<template>
  <Page class="page-items page-new-item">
    <template #title>Nuova offerta</template>

    <template #title-extra>
      <router-link class="btn btn-outline-light" :to="{ name: 'items.list' }">Indietro</router-link>
    </template>

    <template #default>
      <ItemForm :feedback.sync="feedback" @submitForm="submit" />
    </template>
  </Page>
</template>

<script>

import Page from '@/views/components/Private/Page.vue';
import ItemForm from '@/views/components/Form/ItemForm.vue';

export default {
  components: {
    Page,
    ItemForm,
  },
  data: () => ({ feedback: null }),
  methods: {
    submit (itemForm) {
      const item = { ...itemForm };

      this.$api.createItem(item)
        .then(res => {
          this.feedback = true;
          this.$router.push({ name: 'items.show', params: { id: res.data.id } });
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
  },
};

</script>
